<template>
   <div class="container py-4 py-lg-5">
     <div class="row justify-content-center">
        <div class="col-lg-10">
          <h4 class="text-center mb-4 mb-lg-5">
            <span class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 text-dark fw-medium pb-2 fw-lg-light border-bottom border-dark border-2">季節限定</span>
          </h4>
          <div class="row">
            <div class="col-lg-6 list-mb" v-for="list in seasonList" :key="list">
              <a :href="list.targetUrl">
                <img class="img-fluid" :src="list.image" alt="季節限定">
              </a>
            </div>
          </div>
        </div>
    </div>
   </div>
</template>

<script>
import BannerSvc from '@/service/banner-service.js'

export default {
  data () {
    return {
      seasonList: ''
    }
  },
  methods: {
    getSeasonList () {
      BannerSvc.GetEventBanner('Season_Event').then((data) => {
        if (data) {
          this.seasonList = data
        }
      })
    }
  },
  mounted () {
    this.getSeasonList()
  }
}
</script>

<style lang="scss" scoped>
.list-mb{
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 35px;
  }
}
</style>
